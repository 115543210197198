// classio 
// const instId = 49;

// providya
// const instId = 152;

//lecture dekho 
// const instId = 156;

//vsmart dekho 
// const instId = 149;

//djc dekho 
const instId = 110;

export default instId;