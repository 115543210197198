import { Box, Typography, Button, Divider, Grid, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../network/endpoints";
import instId from "../network/instituteId";
import axios from "axios";
import Network from "../network/Network";
import parse from "html-react-parser";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FaQqSection from "../homepage/FaqSection";
import IframFormSection from "../homepage/IframeSection";

const FreeResources = () => {

    const isMobile = useMediaQuery("(min-width:600px)");
    const [Endpoints, setEndpoints] = useState('');
    const [coursesList, setCoursesList] = useState([]);
    const [selectedSceduleList, setSelectedSceduleList] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
        getInstituteDetail();
        getAllCourses();
    }, []);

    useEffect(() => {
        if (coursesList?.length > 0) {
            getMergedSchedules()
        }
    }, [coursesList])

    const getInstituteDetail = async () => {
        try {
            let requestOptions = {
                // headers: { "X-Auth": token },
                withCredentials: false,
            };
            const response = await axios.get(
                BASE_URL + "/getMetaData/fetch-institute/" + instId,
                requestOptions
            );
            if (response?.data?.errorCode === 0) {
                setEndpoints(response?.data?.instituteTechSetting?.mediaUrl)
                // Endpoints = response?.data?.instituteTechSetting?.mediaUrl
            };
        } catch (error) {
            console.log(error);
        }
    };

    const getAllCourses = async () => {
        const response = await Network.fetchCourses(instId);
        const course = response?.courses || [];


        const filteredCourses = course.filter(course =>
            course?.active === true &&
            course?.tags?.some(tagObj => tagObj?.tag?.toLowerCase() === "free")
        );

        setCoursesList(filteredCourses);
    };

    const getMergedSchedules = async () => {
        try {
            let allSchedules = [];

            await Promise.all(
                coursesList.map(async (course) => {
                    const response = await Network.fetchCheduleApi(course.id, 0);
                    if (response?.contentList) {
                        allSchedules = [...allSchedules, ...response.contentList];
                    }
                })
            );
            setSelectedSceduleList(allSchedules);
        } catch (error) {
            console.error("Error fetching course schedules:", error);
        }
    };

    const handleCardClick = (item) => {
        if (item?.entityType === "video" && item?.video?.video) {
            window.open(Endpoints + item?.video?.video, "_blank");
        } else if (item?.entityType === "note" && item?.note?.note) {
            window.open(Endpoints + item?.note?.note, "_blank");
        } else {
            console.warn("No valid URL found for this item.");
        }
    };



    return (
        <React.Fragment>
            <div className="" style={{ padding: '8rem 0 0 0' }}>
                <div className="col-xs-12 col-md-12 col-lg-12" style={{ background: "#fff", paddingLeft: "0px", paddingRight: "0px" }}>
                    <div style={{ paddingLeft: isMobile ? '0rem' : '0rem', paddingRight: isMobile ? '0rem' : '0rem', paddingTop: isMobile ? '0rem' : '0rem', paddingBottom: isMobile ? '4rem' : '0rem' }}>
                        <Grid container sx={{ paddingBottom: isMobile ? "" : "20px", pt: isMobile ? 4 : 4 }}>
                            {
                                selectedSceduleList?.length > 0 && selectedSceduleList?.map((item, i) => {
                                    if (item?.paid === false) {
                                        return <Grid item xs={12} sm={2} md={2} lg={2} sx={{ padding: "10px", textAlign: "center", pl: 2, }}>
                                            <Box sx={{ textAlign: "left", borderRadius: "10px", position: "relative", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", cursor: "pointer" }} onClick={() => handleCardClick(item)}>
                                                {
                                                    item?.thumb && (
                                                        <img src={Endpoints + item?.thumb} style={{ width: "100%", borderRadius: "8px" }} />
                                                    )
                                                }

                                                <Box sx={{ pb: 1 }}>
                                                    <Typography variant='h5' fontWeight={"bold"} sx={{ mt: 2, mb: 2, ml: 2, mr: 2, color: "black", pt: 2, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                        {item?.title} &nbsp; {item?.entityType === "video" ? <PlayCircleIcon fontSize={'large'} /> : <PictureAsPdfIcon fontSize={'large'} />}

                                                    </Typography>
                                                    <Typography variant='p' sx={{ fontSize: "12px", ml: 2, mb: 2, mr: 2, display: "flex", justifyContent: "space-between" }}>
                                                        {/* {item?.description?.description ? parse(item?.description?.description) : ""} */}
                                                        {item.createdAt ? new Date(item.createdAt).toLocaleString('en-US', {
                                                            month: 'numeric',
                                                            day: 'numeric',
                                                            year: 'numeric',
                                                            hour: 'numeric',
                                                            minute: 'numeric',
                                                            hour12: true
                                                        }) : ""}
                                                        <Typography sx={{ color: "#09c23d", fontSize: "14px" }}>FREE</Typography>

                                                    </Typography>
                                                </Box>
                                                {/* <Box sx={{ position: "absolute", bottom: "0", left: 0, right: 0, padding: "0px 10px 0 10px" }}>
                                                <Button
                                                    sx={{ background: item.id === selectedPlan?.id ? "#001C4A" : "#1354C1", color: "#fff", margin: "10px 0px 10px 0px", width: "100%", fontWeight: "bold", padding: "5px", fontSize: "12px" }}
                                                    
                                                    className='button-hover'
                                                >
                                                    {item.id === selectedPlan?.id ? "Selected" : "Select Plan"}
                                                </Button>
                                            </Box> */}
                                            </Box>
                                        </Grid>
                                    }
                                })
                            }
                        </Grid>
                    </div>
                </div>

            </div>
            {/* <IframFormSection endpointsUrl={Endpoints} /> */}
        </React.Fragment>
    );
};

export default FreeResources;
