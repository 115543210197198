import { Box, Button, Chip, Dialog, DialogActions, DialogContent, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import 'react-multi-carousel/lib/styles.css';
import Carousel from 'react-multi-carousel';
import { Base64 } from 'js-base64';
import instId from '../network/instituteId';
import { BASE_URL } from '../network/endpoints';
import Network from '../network/Network';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import parse from "html-react-parser";


export const CourseDrips = () => {

    const scheduleRef = useRef(null);
    const planRef = useRef(null);
    const isMobile = useMediaQuery("(min-width:600px)");
    const location = useLocation();
    const courseObj = location?.state?.data
    const navigate = useNavigate()
    const queryParam = new URLSearchParams(location.search);
    const campaignId = queryParam.get("campaignId");
    const [selectedTag, setSelectedTag] = useState('');
    const [course, setCourse] = useState([]);
    const [filterCourse, setFilterCourse] = useState([]);
    const [tagsList, setTagsList] = useState([]);
    const [Endpoints, setEndpoints] = useState('');
    const [coursesList, setCoursesList] = useState([]);
    const [courseExpandedDescriptions, setCourseExpandedDescriptions] = useState(false);
    const [fullDes, setFullDes] = useState('');
    const [courseContentList, setCourseContentList] = useState([]);
    const [selectedSceduleList, setSelectedSceduleList] = useState([]);
    const [schedulePlans, setSchedulePlans] = useState([]);
    const [planList, setPlanList] = useState([]);
    const [selectedContentId, setSelectedContentId] = useState({});
    const [selectedPlan, setSelectedPlan] = useState({});
    const [selectedSchedule, setSelectedSchedule] = useState({});
    const [shouldScrollToSchedule, setShouldScrollToSchedule] = useState(false);
    const [shouldScrollToPlan, setShouldScrollToPlan] = useState(false);

    useEffect(() => {
        if (shouldScrollToSchedule && schedulePlans?.length > 0 && scheduleRef.current) {
            scheduleRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
            setShouldScrollToSchedule(false);
        }
    }, [schedulePlans, shouldScrollToSchedule]);

    useEffect(() => {
        if (shouldScrollToPlan && planList?.length > 0 && planRef.current) {
            planRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
            setShouldScrollToPlan(false);
        }
    }, [planList, shouldScrollToPlan]);

    useEffect(() => {
        window.scrollTo(0, 0);
        getAllCourses();
        getInstituteDetail();
    }, []);

    useEffect(() => {
        if (courseObj?.id) {
            getCourseSchedule(courseObj?.id, 0)
        }
    }, [courseObj]);

    useEffect(() => {
        if (courseContentList?.length > 0) {
            setSelectedSchedule(courseContentList[0])
            getSelectedSchedule(courseObj?.id, courseContentList[0]?.id, 'first')
        }
    }, [courseContentList]);



    const getAllCourses = async () => {
        const response = await Network.fetchCourses(instId);
        const course = response?.courses;
        const filterCourses = course?.filter((item) => item?.active === true);
        setCoursesList(filterCourses);
    };

    const getInstituteDetail = async () => {
        try {
            let requestOptions = {
                // headers: { "X-Auth": token },
                withCredentials: false,
            };
            const response = await axios.get(
                BASE_URL + "/getMetaData/fetch-institute/" + instId,
                requestOptions
            );
            if (response?.data?.errorCode === 0) {
                setEndpoints(response?.data?.instituteTechSetting?.mediaUrl)
                // Endpoints = response?.data?.instituteTechSetting?.mediaUrl
            };
        } catch (error) {
            console.log(error);
        }
    };

    const getCourseSchedule = async (courseId, contentId) => {
        try {
            const response = await Network.fetchCheduleApi(courseId, contentId);
            if (contentId === 0) {
                // First call, store course content list
                setCourseContentList(response?.contentList || []);
            }
        } catch (error) {
            console.error("Error fetching course schedule:", error);
        }
    };

    const getSelectedSchedule = async (courseId, contentId, value) => {
        try {
            const response = await Network.fetchCheduleApi(courseId, contentId);
            if (value === "first") {
                setSelectedSceduleList(response?.contentList || []);
            }
            if (value === "second") {
                setSchedulePlans(response?.contentList || []);
            }
            if (value === "third") {
                setPlanList(response?.contentList || []);
            }

        } catch (error) {
            console.error("Error fetching course schedule:", error);
        }
    };

    const handleContentClick = (contentId) => {
        setPlanList([]);
        setSelectedContentId(contentId);
        getSelectedSchedule(courseObj?.id, contentId?.id, 'second');

        setShouldScrollToSchedule(true);
    };

    const handleSelectPlan = (contentId) => {

        if (selectedContentId?.title === "Full Length Test Series") {
            let data = Base64.encode(JSON.stringify({
                courseObj: courseObj, selectedPlanData:
                    "", selectedSchedule: selectedSchedule, selectScheduleContent: selectedContentId, basicPlan: contentId
            }), true)
            navigate('/test-series?data=' + data);
        } else {
            setSelectedPlan(contentId);
            getSelectedSchedule(courseObj?.id, contentId?.id, 'third');
            setShouldScrollToPlan(true);
        }

    };

    const toggleExpandDescription = (des) => {
        setFullDes(des)
        setCourseExpandedDescriptions(true);
    };

    const truncateDescription = (description) => {
        // Replace &nbsp; and other HTML entities with plain text equivalents
        const decodedDescription = description
            ?.replace(/&nbsp;/g, ' ')
            ?.replace(/&amp;/g, '&') // Example for handling other entities, can add more if needed
            ?.replace(/&lt;/g, '<')
            ?.replace(/&gt;/g, '>')
            ?.replace(/&quot;/g, '"')
            ?.replace(/&#39;/g, "'");

        // Strip any remaining HTML tags
        const strippedDescription = decodedDescription
            ?.replace(/<[^>]*>/g, ' ') // Remove HTML tags
            .split(/\s+/)
            .slice(0, 10) // Get first 10 words
            .join(' ');

        return strippedDescription;
    };


    const handleTestDetail = (item) => {
        let data = Base64.encode(JSON.stringify({ courseObj: courseObj, selectedPlanData: item, selectedSchedule: selectedSchedule, selectScheduleContent: selectedContentId, basicPlan: selectedPlan }), true)
        navigate('/test-series?data=' + data)
    }


    return (
        <div id="testseries" className="" style={{ margin: "40px", textAlign: 'left', padding: '12rem 0 0 0' }}>
            <Grid container spacing={2} sx={{ pl: 2, pr: 2 }}>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Typography variant="h4"
                        sx={{ mb: 2 }}
                        fontSize={'2.2rem'}
                        fontWeight={'bold'}
                    >
                        Lets Personalize {courseObj?.title} Test Series for you
                    </Typography>
                    <Typography sx={{ mb: 2, fontSize: "15px" }}>
                        Personalizing your {courseObj?.title} Test Series is simple and efficient. Start by selecting the Test Series Portion Type, followed by choosing the Test Series Plan that suits your needs.  Next, determine the Level of Premiumness for added benefits. Finally, generate your Customized Test Series Plan and get started with your preparation!
                    </Typography>
                </Grid>
            </Grid>
            <Typography sx={{ mb: 1, display: "flex", alignItems: "center", pl: 2, color: "#000", fontSize: "15px" }}>
                Select Test Series Portion Type &nbsp; <ArrowForwardIcon />
            </Typography>
            <Grid container spacing={2} sx={{ my: 1, pl: 2, mb: 3 }}>
                {selectedSceduleList?.map((content) => (
                    <Grid item key={content.id}>
                        <Button
                            sx={{ background: content.id === selectedContentId?.id ? "#001C4A" : "#1354C1", padding: "15px 20px", fontSize: "14px" }}
                            variant="contained"
                            onClick={() => handleContentClick(content)}
                        >
                            {content.title}
                        </Button>
                    </Grid>
                ))}
            </Grid>
            <Box ref={scheduleRef}></Box>
            {
                schedulePlans?.length > 0 && (
                    <Typography sx={{ mt: 2, mb: 1, display: "flex", alignItems: "center", pl: 2, color: "#000", fontSize: "15px" }}>
                        Select Test Series Plan Type &nbsp; <ArrowForwardIcon />
                    </Typography>
                )
            }
            <Grid container>
                {
                    schedulePlans?.length > 0 && schedulePlans?.map((item, i) => {
                        return <Grid item xs={12} sm={2.4} md={2.4} lg={2.4} sx={{ padding: "10px", textAlign: "center", pl: 2, }}>
                            <Box sx={{ borderRadius: "10px", position: "relative", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                                <Box sx={{ pb: 4 }}>
                                    <Typography variant='h5' fontWeight={"bold"} sx={{ mt: 2, mb: 2, color: "black", pt: 2 }}>
                                        {item?.title}

                                    </Typography>
                                    <Typography variant='p' className='desktop-view-discrip' sx={{ fontSize: "12px", margin: "0px 10px", mb: 4 }}>
                                        {item?.description?.description ? parse(item?.description?.description) : ""}

                                    </Typography>
                                </Box>
                                <Box sx={{ position: "absolute", bottom: "0", left: 0, right: 0, padding: "0px 10px 0 10px" }}>
                                    <Button
                                        sx={{ background: item.id === selectedPlan?.id ? "#001C4A" : "#1354C1", color: "#fff", margin: "10px 0px 10px 0px", width: "100%", fontWeight: "bold", padding: "5px", fontSize: "12px" }}
                                        onClick={() => handleSelectPlan(item)}
                                        className='button-hover'
                                    >
                                        {item.id === selectedPlan?.id ? "Selected" : "Select Plan"}
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                    })
                }
            </Grid>
            <Box ref={planRef}></Box>
            {
                planList?.length > 0 && (
                    <Typography sx={{ mt: 3, mb: 2, display: "flex", alignItems: "center", pl: 2, color: "#000", fontSize: "15px" }}>
                        Select Programme Level Type   &nbsp; <ArrowForwardIcon />
                    </Typography>
                )
            }
            <Grid container>
                {
                    planList?.length > 0 && planList?.map((item, i) => {
                        return <Grid item xs={12} sm={2.4} md={2.4} lg={2.4} sx={{ padding: "10px", textAlign: "center", pl: 2, }}>
                            <Box sx={{ borderRadius: "10px", position: "relative", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                                <Box sx={{ pb: 4 }}>
                                    <Typography variant='h5' fontWeight={"bold"} sx={{ mt: 2, mb: 2, color: "black", pt: 2 }}>
                                        {item?.title}

                                    </Typography>
                                    <Typography variant='p' className='desktop-view-discrip' sx={{ fontSize: "12px", margin: "0px 10px", mb: 4 }}>
                                        {item?.description?.description ? parse(item?.description?.description) : ""}
                                    </Typography>
                                </Box>
                                <Box sx={{ position: "absolute", bottom: "0", left: 0, right: 0, padding: "0px 10px 0 10px" }}>
                                    <Button
                                        sx={{ background: "#1354C1", color: "#fff", margin: "10px 0px 10px 0px", width: "100%", fontWeight: "bold", padding: "5px", fontSize: "12px" }}
                                        onClick={() => handleTestDetail(item)}
                                        className='button-hover'
                                    >
                                        Select Programme
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                    })
                }
            </Grid>
            {/* <Grid container spacing={2} sx={{ pl: 2, pr: 2, mt: 3, textAlign: "center" }}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Button
                        sx={{ background: "#1354C1", padding: "10px 20px", width: "20%" }}
                        variant="contained"
                    // onClick={() => handleContentClick(content.id)}
                    >
                        Generate Test Series
                    </Button>
                </Grid>
            </Grid> */}

            <Dialog open={courseExpandedDescriptions} onClose={() => setCourseExpandedDescriptions(false)}>

                <DialogContent dividers>
                    <Typography variant='body1'>
                        {parse(fullDes)}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setCourseExpandedDescriptions(false)}>Close</Button>
                </DialogActions>
            </Dialog>

        </div>
    );
};
