import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Tooltip, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import instId from "./network/instituteId";
import Network from "./network/Network";


export const ReelsSection = ({ endpointsUrl, instituteRes }) => {

    const isMobile = useMediaQuery("(min-width:600px)");
    const [shortsList, setShortsList] = useState([]);
    const [peviewImgVideo, setPeviewImgVideo] = useState({});    

    useEffect(() => {
        getBanners();

    }, []);

    const getBanners = async () => {

        try {
            const response = await Network.getBannersApi(instId);
            const fetchedBanners = response.banners || [];
            if (fetchedBanners.length > 0) {
                setShortsList(fetchedBanners);
            } else {
                setShortsList([]);
            }
        } catch (error) {
            setShortsList([]);
        }
    };


    const handlePreview = (url, type, itemId) => {
        setPeviewImgVideo((prev) => ({ ...prev, [itemId]: { url, type } }));
    };

    return (
        <Box id="whyfast" className="text-center" sx={{ mt: 3, mb: 8, padding: "0rem 0rem" }}>
            {
                instituteRes?.gallery?.length > 0 && (
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography
                            variant="h3"
                                sx={{ mb: 3, mt: 4, color: "black", padding: "10px" }}
                                // fontSize={'16px'}
                                fontWeight={'bold'}
                            >Overwhelming <span style={{ color: "#1250B9" }}>Success</span> in feedback</Typography>
                            <Swiper
                                autoplay={{
                                    delay: 2000,
                                    disableOnInteraction: true,
                                }}
                                centeredSlides={true}
                                breakpoints={{
                                    320: { slidesPerView: 1 },
                                    768: { slidesPerView: 2 },
                                    1024: { slidesPerView: 4 }
                                }}
                                spaceBetween={10}
                                navigation={true}
                                pagination={false}
                                modules={[Navigation, Autoplay]}
                            >
                                {instituteRes?.gallery?.length > 0  && instituteRes?.gallery.map((item, i) => (
                                    <SwiperSlide key={i}>
                                        <Grid item xs={12} sm={11} md={11} lg={11} sx={{ padding: "10px", textAlign: "center" }}>
                                            <Box sx={{
                                                borderRadius: "10px", position: "relative", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                                                padding: !isMobile ? "20px" : "5px"
                                            }}>

                                                <img
                                                    alt={item?.title}
                                                    src={endpointsUrl + item}
                                                    style={{ width: "100%", minHeight: "220px", borderBottom: "1px solid #a9a9a92e", borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }} />

                                            </Box>
                                        </Grid>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </Grid>
                    </Grid>
                )
            }
        </Box>
    );
};
