import { Box, Button, Dialog, DialogActions, DialogContent, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import TelegramIcon from '@mui/icons-material/Telegram';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Network from "./network/Network";
import instId from "./network/instituteId";

export const Footer = ({aboutUsRef}) => {

  const isMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const [coursesList, setCoursesList] = useState([]);
  const [contactUsModal, setContactUsModal] = useState(false)

  useEffect(() => {
    getAllCourses();
  }, []);

  const getAllCourses = async () => {
    const response = await Network.fetchCourses(instId);
    const course = response?.courses;
    const filterCourses = course?.filter((item) => item?.active === true);
    setCoursesList(filterCourses);
  };

  const handleAboutUs = () => {
    aboutUsRef.current?.scrollIntoView({ behavior: "smooth" });
  }
  const handleTermsAndCondition = () => {
    navigate('/terms-conditions')
  }

  const handlePrivacyPolicy = () => {
    navigate('/privacypolicy')
  }
  

  const handleContactUsClick = () => {
    navigate("/", { state: { scrollTo: "contact" } });
  };

  const handleCaInterCourse = (item) => {
    const value = coursesList.find(course => course.title === item);
    if (value) {
      navigate('/course-content', { state: { data: value } });
    } else {
      console.error("Course not found");
    }
  }

  const handleContactUs = () => {
    setContactUsModal(true)
  }

  return (
    <div>
      <div id="contact">
        <Grid container sx={{ padding: isMobile ? "50px 50px 10px 50px" : "20px" }} className="footer-first-section">
          <Grid item xs={12} sm={8} md={8} lg={8}>
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2, fontSize: "15px" }}>
              CS Executive Lectures |  JIGL Lectures | Company Law Lectures | SBILL Lectures | CAFM Lectures | CMSL Lectures |
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2, fontSize: "15px" }}>
              ECIPL Lectures | Tax Laws Lectures | Corporate & Management Accounting Lectures | CS Professional Lectures |
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2, fontSize: "15px" }}>
              Governance, Risk Management, Compliances & Ethics (GRMCE) Lectures | Advanced Tax Laws Lectures |
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2, fontSize: "15px" }}>
              Drafting, Pleadings & Appearances Lectures | Secretarial Audit, Compliance Management & Due Diligence Lectures |
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 8, fontSize: "15px" }}>
              Intellectual Property Rights – Laws & Practices Lectures | Labour Laws & Practice Lectures |
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2, fontSize: "15px" }}>
              Need any help, Feel Free to reach us out :  +91-8827692257 |  dushyantjainclasses@gmail.com
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2} md={2} lg={2}>
            <Box>
              <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2, fontSize: "15px", cursor: "pointer" }} onClick={handleAboutUs}>
                About Us
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2, fontSize: "15px", cursor: "pointer" }} onClick={handleContactUs}>
                Contact Us
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2, fontSize: "15px", cursor: "pointer" }}>
                Refund Policy
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2, fontSize: "15px", cursor: "pointer" }} onClick={handlePrivacyPolicy}>
                Privacy Policy
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2, fontSize: "15px", cursor: "pointer" }} onClick={handleTermsAndCondition}>
                Terms and Conditions
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={2} md={2} lg={2}>
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2, fontSize: "15px" }}>
              4th Floor, Office, Shreevardhan
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2, fontSize: "15px" }}>
              Complex, 408B, RNT Marg,
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2, fontSize: "15px" }}>
            South  Tukoganj, Indore, 452001 M.P.
            </Typography>
            <div className="row">
              <div className="social" style={{ marginLeft: "5px" }}>
                <ul>
                  <li>
                    <a href={"https://www.facebook.com/share/17bTY78zLB/?mibextid=wwXIfr"} target="_blank" rel="noreferrer">
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={"https://www.instagram.com/dushyant_dushyantjainclasses?igsh=MTF4ZXd6Y2l6b3ZvYQ=="} target="_blank" rel="noreferrer">
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href={"https://www.youtube.com/results?search_query=dushyant+jain+cs+classes"} target="_blank" rel="noreferrer">
                      <i className="fa fa-youtube"></i>
                      {/* <i className="fa fa-globe"></i> */}
                    </a>
                  </li>
                  <li>
                    <a href={"https://api.whatsapp.com/send/?phone=	8827692257&text=Hi+Team%2C+is+there+any+related+service+available+%3F&type=phone_number&app_absent=0"} target="_blank" rel="noreferrer">
                      <i className="fa fa-whatsapp"></i>
                    </a>
                  </li>
                  <li>
                    <a href={"https://www.linkedin.com/in/dushyant-jain-528748211?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"} target="_blank" rel="noreferrer">
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a href={"https://t.me/8827692257"} target="_blank" rel="noreferrer">

                      <i class="fa fa-telegram" ><TelegramIcon fontSize='large' /></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
              © Copyright Dushyant Jain Classes
            </Typography>
          </Grid>
        </Grid>
      </div>
      <Dialog open={contactUsModal} onClose={() => setContactUsModal(false)}>
        <DialogContent sx={{ flex: 1, padding: 0 }}>  {/* Remove padding and allow full height */}
          <Grid container sx={{ height: "100%" }}>
            <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" sx={{ height: "100%", padding: "20px" }}>
              <iframe
                style={{
                  width: "100%",
                  height: "100%",
                  minHeight: "500px",
                  border: "none"
                }}
                src={`https://forms.classiolabs.com/?instituteid=${instId}`}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setContactUsModal(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
