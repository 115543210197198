import React, { useEffect, useState } from "react";
import { Box, Button, Grid, useMediaQuery } from "@mui/material";
import instId from "./network/instituteId";
import Network from "./network/Network";
import CustomCarousel from "./CustomCarosoul";

export const SecondSection = ({ endpointsUrl }) => {

  const isMobile = useMediaQuery("(min-width:600px)");
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    getBanners();
  }, []);

  const getBanners = async () => {
    try {
      const response = await Network.fetchBannerss(instId);
      const fetchedBanners = response.banners || [];
      let reusltBanner = [];
      fetchedBanners.forEach((item) => {
        // console.log('item', item)
        if (item) {
          reusltBanner.push(item);
        };
      })
      // setBanners(reusltBanner || []);
      if (reusltBanner.length > 0) {
        const extendedBanners = [...reusltBanner, reusltBanner[0]];
        setBanners(extendedBanners);
      } else {
        setBanners([]);
      }
    } catch (error) {
      console.error('Failed to fetch banners:', error);
      setBanners([]);
    }
  };


  return (
    <div className="text-center">
      <div className="">
        <div className="col-xs-12 col-md-12 col-lg-12" style={{ marginTop: '15px' }}>
          <div style={{ paddingLeft: isMobile ? '0rem' : '0rem', paddingRight: isMobile ? '0rem' : '0rem', paddingTop: isMobile ? '0rem' : '1rem', paddingBottom: isMobile ? '4rem' : '0rem' }}>
            {banners?.length > 0 && (
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} sx={{
                  // background: '#b7b2b221', 
                  width: "100%", borderRadius: "15px"
                }}>
                  <CustomCarousel banners={banners} setBanners={setBanners} endpointsUrl={endpointsUrl} />
                </Grid>
              </Grid>
            )}

          </div>
        </div>
      </div>
    </div>
  );
};
