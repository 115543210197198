import { Box, Button, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import dayjs from 'dayjs';
import instId from "../network/instituteId";
import Network from "../network/Network";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const FacultySection = ({ endpointsUrl }) => {

    const isMobile = useMediaQuery("(min-width:600px)");

    const [activeIndex, setActiveIndex] = useState(0);
    const [employee, setEmployee] = useState([]);
    const settings = {
        // dots: true,
        infinite: employee?.length < 2 ? false : true,
        speed: 500,
        slidesToShow: isMobile ? 5 : 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        beforeChange: (current, next) => setActiveIndex(next), // Update activeIndex on slide change
        customPaging: (i) => (
            <div
                style={{
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%",
                    backgroundColor: i === activeIndex ? "#ED1B23" : "#FFD700", // Change color based on active index
                    margin: "0 5px",
                    cursor: "pointer",
                }}
            />
        ),
        appendDots: (dots) => (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "20px",
                }}
            >
                {dots}
            </div>
        ),
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                },
            },
        ],
    };

    useEffect(() => {
        getEmployee();
    }, []);

    const getEmployee = async () => {
        try {
            const response = await Network.fetchEmployee(instId);
            setEmployee(response?.employees);

        } catch (error) {
            console.error("Error fetching employees:", error);
        }
    };


    return (
        <React.Fragment>
            <div style={{ paddingLeft: isMobile ? '6rem' : '1.3rem', paddingRight: isMobile ? '6rem' : '1rem', }}>
                <Grid container spacing={2}>

                    <Grid item xs={12} sm={12} md={12} lg={12}
                        sx={{
                            borderRadius: '10px',
                            background: '#fff',
                            py: [2, 2]
                        }}
                    >
                        {
                            employee?.length > 0 && (
                                <Typography
                                    variant="h4"
                                    sx={{ mt: 4, mb: 6, color: "black", textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}
                                    // fontSize={'16px'}
                                    fontWeight={'bold'}
                                >Meet the Core Team Behind DUSHYANT JAIN CLASSES</Typography>
                                // <Typography
                                //     sx={{ mt: 4, mb: 6, color: "black", textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}
                                //     fontSize={isMobile ? '16px' : "12px"}
                                //     fontWeight={'bold'}
                                // >Meet the Core Team Behind DUSHYANT JAIN CLASSES</Typography>
                            )
                        }
                        {
                            <Slider className="employee-slider" {...settings}>
                                {employee.map((item, index) => {
                                    const joiningDate = dayjs(item.joining);
                                    const today = dayjs();
                                    const years = today.diff(joiningDate, "year");
                                    const months = today.diff(joiningDate, "month") % 12;
                                    const experience = `${years} Year${years > 1 ? "s" : ""} ${months} Month${months > 1 ? "s" : ""}`;

                                    return (
                                        <Box key={index}> {/* ✅ Correct placement of key */}
                                            <Stack
                                                direction={'column'}
                                                sx={{
                                                    width: '100%',
                                                    maxWidth: isMobile ? '250px' : '250px',
                                                    margin: 'auto',
                                                }}
                                            >
                                                <img
                                                    alt=""
                                                    src={item.profile ? endpointsUrl + item.profile : 'img/employee.svg'}
                                                    style={{ width: '100%' }}
                                                />
                                                <Box
                                                    sx={{
                                                        backgroundColor: '#114CAF',
                                                        borderRadius: '15px 15px 0px 0px',
                                                        py: 1,
                                                    }}
                                                >
                                                    <Typography fontSize={'18px'} fontWeight={'500'} textAlign={'center'} py={0.2} color="#fff">
                                                        {item?.firstName + ' ' + item?.lastName || 'John Doe'}
                                                    </Typography>
                                                    <Typography fontSize={'14px'} fontWeight={'500'} textAlign={'center'} py={0.2} color="#fff">
                                                        {item.designation || 'English Language'}
                                                    </Typography>
                                                    <Typography fontSize={'14px'} fontWeight={'500'} textAlign={'center'} py={0.2} color="#fff">
                                                        {experience || '12 Year Experience'}
                                                    </Typography>
                                                </Box>
                                            </Stack>
                                        </Box>
                                    );
                                })}
                            </Slider>
                        }
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    )
};

export default FacultySection;