import { Box, Typography, Button, Divider, Grid, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Navigation } from "./navigation";
import { Footer } from "./Footer";
import Network from "./network/Network";
import CustomCarousel from "./CustomCarosoul";
import Endpoints, { BASE_URL } from "./network/endpoints";
import instId from "./network/instituteId";
import axios from "axios";
import HomeSection2 from "./homepage/RatingSection";
import FacultySection from "./homepage/FacultyList";
import IframFormSection from "./homepage/IframeSection";

const AboutUs = () => {

    const isMobile = useMediaQuery("(min-width:600px)");
    const [banners, setBanners] = useState([]);
    const [Endpoints, setEndpoints] = useState('')

    useEffect(() => {
        window.scrollTo(0, 0);
        getInstituteDetail();
        getBanners();

    }, []);

    const getInstituteDetail = async () => {
        try {
            let requestOptions = {
                // headers: { "X-Auth": token },
                withCredentials: false,
            };
            const response = await axios.get(
                BASE_URL + "/getMetaData/fetch-institute/" + instId,
                requestOptions
            );
            if (response?.data?.errorCode === 0) {
                setEndpoints(response?.data?.instituteTechSetting?.mediaUrl)
                // Endpoints = response?.data?.instituteTechSetting?.mediaUrl
            };
        } catch (error) {
            console.log(error);
        }
    };

    const getBanners = async () => {
        try {
            const response = await Network.fetchBannerss(instId);
            const fetchedBanners = response.banners || [];
            let reusltBanner = [];
            fetchedBanners.forEach((item) => {
                if (item?.group === "About Us") {
                    reusltBanner.push(item);
                };
            })
            // setBanners(reusltBanner || []);
            if (reusltBanner.length > 0) {
                const extendedBanners = [...reusltBanner, reusltBanner[0]];
                setBanners(extendedBanners);
            } else {
                setBanners([]);
            }
        } catch (error) {
            console.error('Failed to fetch banners:', error);
            setBanners([]);
        }
    };

    return (
        <React.Fragment>
            <div className="" style={{ padding: '8rem 0 0 0' }}>
                <div className="col-xs-12 col-md-12 col-lg-12" style={{ marginTop: '30px', background: "#fff" }}>
                    <div style={{ paddingLeft: isMobile ? '0rem' : '0rem', paddingRight: isMobile ? '0rem' : '0rem', paddingTop: isMobile ? '0rem' : '0rem', paddingBottom: isMobile ? '4rem' : '0rem' }}>
                        <Grid container>
                            {banners?.length > 0 && banners?.map((item, i) => {
                                return <Grid item xs={12} sm={12} md={12} lg={12} sx={{paddingTop: "20px"}}>
                                    <img
                                        src={Endpoints + item?.banner}
                                        alt={`slide-${i}`}
                                        style={{
                                            width: "100%",
                                            // height: "80%",
                                            // width: isCenterImage ? '1000px' : '700px',
                                            // height: isCenterImage ? '350px' : '200px',
                                            borderRadius: '10px',
                                            transition: 'transform 0.3s ease',
                                        }}
                                    />
                                    {/* <CustomCarousel banners={banners} setBanners={setBanners} endpointsUrl={Endpoints} /> */}
                                </Grid>
                            })}

                        </Grid>
                    </div>
                </div>
            </div>
            <HomeSection2 endpointsUrl={Endpoints} />
            <FacultySection endpointsUrl={Endpoints} />
            {/* <IframFormSection endpointsUrl={Endpoints} /> */}
        </React.Fragment>
    );
};

export default AboutUs;
