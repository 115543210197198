import { AppBar, Box, Button, Chip, Dialog, DialogActions, DialogContent, Grid, Stack, Tab, Tabs, Tooltip, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react'
import Network from './network/Network';
import instId from './network/instituteId';
import { useNavigate } from 'react-router-dom';
import parse from "html-react-parser";
import Endpoints from './network/endpoints';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import SuggestedCourseDialog from './cartCourses/LectueMode';

const TestSeriesSection = ({ endpointsUrl }) => {

    let cartData = localStorage.getItem('cartArray');
    const isMobile = useMediaQuery("(min-width:600px)");
    const [isHovered, setIsHovered] = useState(false);
    const [isHovered1, setIsHovered1] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);
    const navigate = useNavigate();
    const [coursesList, setCoursesList] = useState([]);
    const [tagsList, setTagsList] = useState([]);
    const [courseExpandedDescriptions, setCourseExpandedDescriptions] = useState(false);
    const [fullDes, setFullDes] = useState('');
    const [value, setValue] = useState(0);
    const [filterCourse, setFilterCourse] = useState([]);
    const [selectedTag, setSelectedTag] = useState('');
    const [cartCourses, setCartCourses] = useState([]);
    const [addedSuggestCourse, setAddedSuggestCourse] = useState({});
    const [suggestedCourseId, setSuggestedCourseId] = useState(null);
    const [suggestedCourseDialog, setSuggestedCourseDialog] = useState(false);
    const [finalAmountsss, setFinalAmountsss] = useState(0);
    const [finalAmounts, setFinalAmounts] = useState(0);


    useEffect(() => {
        if (cartData !== null && cartData !== undefined) {
            setCartCourses(cartData ? JSON.parse(cartData) : [])
        }
    }, [cartData])

    useEffect(() => {
        getTagsList();
        getAllCourses();
    }, []);

    useEffect(() => {
        if (tagsList?.length > 0) {
            const filterCourseTags = coursesList.filter(item => {
                const tagslists = item.tags || [];
                if (tagslists.some(tag => tag.id === selectedTag.id)) {
                    return item
                }
            });

            setFilterCourse(filterCourseTags);
        }
    }, [coursesList, tagsList, selectedTag])

    const getTagsList = async () => {
        try {

            const response = await Network.fetchTags(instId);
            if (response?.errorCode === 0) {
                setTagsList(response?.tags)
                const selectedSeriesByTitle = response?.tags;
                setSelectedTag(selectedSeriesByTitle[0])
                // const testByTitle = selectedSeriesByTitle.find(
                //     (item) => item.tag === "CA Test Series"
                // );
            };

        } catch (error) {
            console.log(error);
        }
    };

    const getAllCourses = async () => {
        const response = await Network.fetchCourses(instId);
        const course = response?.courses;
        const filterCourses = course?.filter((item) =>
            item?.active === true
        );
        setCoursesList(filterCourses);
    };

    const handleCourseClick = (value) => {
        navigate('/course-content', { state: { data: value } })
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setSelectedTag(tagsList[newValue]);
    };

    const toggleExpandDescription = (des) => {
        setFullDes(des)
        setCourseExpandedDescriptions(true);
    };

    const truncateDescription = (description) => {
        const decodedDescription = description
            .replace(/&nbsp;/g, ' ')
            .replace(/&amp;/g, '&')
            .replace(/&lt;/g, '<')
            .replace(/&gt;/g, '>')
            .replace(/&quot;/g, '"')
            .replace(/&#39;/g, "'");

        const strippedDescription = decodedDescription
            .replace(/<[^>]*>/g, ' ')
            .split(/\s+/)
            .slice(0, 10)
            .join(' ');

        return strippedDescription;
    };

    const chipTitle = (title) => {
        const first10Words = title
            .split(' ')
            .slice(0, 3)
            .join(' ');
        return first10Words;
    }

    const handleAddtoCart = (course) => {

        setCartCourses((prevCart) => {
            const isAlreadyAdded = prevCart.some(item => item.id === course.id);

            let updatedCart;

            if (isAlreadyAdded) {
                updatedCart = prevCart.filter(item => item.id !== course.id);
            } else {
                setAddedSuggestCourse(course);
                setSuggestedCourseId(course.id);
                setSuggestedCourseDialog(true);
                return prevCart;
            }
            localStorage.setItem('cartArray', JSON.stringify(updatedCart));
            window.dispatchEvent(new Event("cartUpdated"));
            return updatedCart;
        });
    };

    const handleCloseSuggestedCourseDialog = () => {
        setSuggestedCourseDialog(false);
    };

    const handleFinalAmountUpdate = (amount) => {
        setFinalAmountsss(amount);
    };

    const handleExplore = () => {
        navigate('/Explore-all-course')
    }


    return (
        <div style={{
            paddingLeft: isMobile ? '6rem' : '1rem', paddingRight: isMobile ? '6rem' : '1rem', paddingTop: isMobile ? '3rem' : '1rem', paddingBottom: isMobile ? '3rem' : '1rem', position: "relative",
            // background: "#00246080" 
        }}
        >
            <Typography
                variant="h4"
                sx={{ mb: 3, mt: 4, color: "black", padding: "10px" }}
                // fontSize={'16px'}
                fontWeight={'bold'}
            >Explore all our <span style={{ color: "#1250B9" }}>Success</span> offerings</Typography>
            {/* <Typography
                sx={{ mb: 3, mt: 4, color: "black" }}
                fontSize={'16px'}
                fontWeight={'bold'}
            >Explore all our <span style={{ color: "#1250B9" }}>Success</span> offerings</Typography> */}
            <AppBar position="static"
                sx={{
                    overflowX: "auto",
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    background: '#fff',
                    borderRadius: '10px',
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                }}
            >
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="inherit"
                    variant="scrollable"
                    scrollButtons="always"
                    sx={{ width: "100%", minWidth: "100%" }}
                >
                    {tagsList.map((item, i) => (
                        <Tab sx={{ color: "#000", fontSize: "14px" }} key={i} label={item?.tag} />
                    ))}
                </Tabs>
            </AppBar>
            <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Swiper
                        navigation={{
                            nextEl: ".swiper-button-next",
                            prevEl: ".swiper-button-prev",
                        }}
                        // loop={true}
                        className="swiper-container"
                        breakpoints={{
                            320: { slidesPerView: 1 },
                            768: { slidesPerView: 2 },
                            1024: { slidesPerView: 4 }
                        }}
                        spaceBetween={10}
                        // navigation={true}
                        pagination={false}
                        modules={[Navigation]}
                    >
                        {filterCourse && filterCourse.map((item, i) => (
                            <SwiperSlide key={i}>
                                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ padding: isMobile ? "10px" : "20px", textAlign: "center" }}>
                                    <Box sx={{
                                        borderRadius: "10px", position: "relative", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                                        //  height: "300px"
                                    }}>
                                        <img
                                            alt={item?.title}
                                            src={endpointsUrl + item?.logo}
                                            style={{ width: "100%", minHeight: "120px", borderBottom: "1px solid #a9a9a92e", borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }} />
                                        <Box sx={{ pb: 4, textAlign: "left", paddingLeft: "15px" }}>
                                            <Tooltip title={item?.title}>
                                                <Typography variant='h5' fontWeight={"bold"} sx={{ mt: 2, mb: 2, color: "black", fontSize: "15px" }}>
                                                    {item?.title?.split(" ").slice(0, 7).join(" ")}
                                                    {item?.title?.split(" ").length > 7 && "..."}
                                                </Typography>
                                            </Tooltip>
                                            <Typography variant='p' className='desktop-view-discrip' sx={{ fontSize: "12px" }}>
                                                {setCourseExpandedDescriptions === false ? truncateDescription(item?.description) : truncateDescription(item?.description)}
                                                {item?.description.length > 100 && (
                                                    <span style={{ color: 'blue', cursor: 'pointer', marginLeft: '5px', textDecoration: 'underline' }} onClick={() => toggleExpandDescription(item?.description)}>
                                                        {setCourseExpandedDescriptions ? 'more' : 'more'}
                                                    </span>
                                                )}
                                            </Typography>
                                            <Box sx={{ marginBottom: "20px", mt: 1 }}>
                                                {item.paid ? (
                                                    item.discount > 0 && item.discount !== null ? (
                                                        <>
                                                            <Typography
                                                                component="span"
                                                                sx={{ fontWeight: '500', background: 'rgba(255, 215, 0, 0.6)', padding: '2px 5px', borderRadius: '4px' }}
                                                            >
                                                                ₹{(Number(item.price) - (Number(item.price) * (Number(item.discount) / 100))).toFixed(2)}
                                                            </Typography>
                                                            &nbsp; <s>₹{item.price}</s> &nbsp;
                                                            <Typography
                                                                component="span"
                                                                sx={{ color: 'red', fontWeight: 'bold' }}
                                                            >
                                                                -{item.discount}%
                                                            </Typography>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {item.price !== null ?
                                                                <Typography
                                                                    component="span"
                                                                    sx={{ fontWeight: '500', background: 'rgba(255, 215, 0, 0.6)', padding: '2px 5px', borderRadius: '4px' }}
                                                                >
                                                                    ₹{parseFloat(item.price).toFixed(2)}
                                                                </Typography>
                                                                :
                                                                <Typography
                                                                    component="span"
                                                                    sx={{ fontWeight: '500', background: 'rgba(255, 215, 0, 0.6)', padding: '2px 5px', borderRadius: '4px' }}
                                                                >
                                                                    ₹0
                                                                </Typography>

                                                            }
                                                        </>
                                                    )
                                                ) : (
                                                    <Typography
                                                        component="span"
                                                        sx={{ fontWeight: '600', background: 'rgba(255, 215, 0, 0.6)', padding: '2px 5px', borderRadius: '4px' }}
                                                    >
                                                        Free
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Box>
                                        <Box sx={{ position: "absolute", bottom: "0", left: 0, right: 0, padding: "0px 10px 0 10px", display: "flex", justifyContent: "space-between" }}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={6} sm={6} md={6} lg={6}>
                                                    <a href={`/course?courseId=${encodeURIComponent(item?.id)}`}>
                                                        <Button
                                                            sx={{ background: "#1356C5", color: "#fff", margin: "10px 0px 10px 0px", width: "100%", fontWeight: "bold", fontSize: "10px", padding: "6px" }}
                                                            // onClick={() => handleEnrollNow(item)}
                                                            className='button-hover'
                                                        >
                                                            View More
                                                        </Button>
                                                    </a>
                                                </Grid>
                                                <Grid item xs={6} sm={6} md={6} lg={6}>
                                                    <Button
                                                        onClick={() => handleAddtoCart(item)}
                                                        sx={{ background: "#0c858b", color: "#fff", margin: "10px 0px 10px 0px", width: "100%", fontWeight: "bold", fontSize: "10px" }}
                                                        className='button-hover'
                                                    >
                                                        {cartCourses.some(a => a.id === item?.id) ? "Remove" : "Add to Cart"}

                                                    </Button>
                                                </Grid>
                                            </Grid>


                                        </Box>
                                    </Box>
                                </Grid>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <div className="swiper-button-prev"></div>
                    <div className="swiper-button-next"></div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box sx={{ textAlign: "center" }}>
                        <Button
                            onClick={handleExplore}
                            sx={{
                                mt: 2,
                                background: '#FEDD3D',
                                color: '#000',
                                fontSize: isMobile ? '16px' : '12px',
                                fontWeight: "bold",
                                px: [10, 7],
                                py: 1,
                                textTransform: 'capitalize',
                                '&:hover': {
                                    background: '#FEDD3D',
                                    color: '#fff',
                                },
                            }}
                        >
                            Explore Now
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <Dialog open={courseExpandedDescriptions} onClose={() => setCourseExpandedDescriptions(false)}>
                <DialogContent dividers>
                    <Typography variant='body1'>
                        {parse(fullDes)}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setCourseExpandedDescriptions(false)}>Close</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={suggestedCourseDialog}
                onClose={() => setSuggestedCourseDialog(false)}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "500px",
                        },
                    },
                }}
            >
                <SuggestedCourseDialog
                    addedSuggestCourse={addedSuggestCourse}
                    // courseId={course}
                    suggestedCourseId={suggestedCourseId}
                    handleClose={handleCloseSuggestedCourseDialog}
                    onFinalAmountUpdate={handleFinalAmountUpdate}
                    setCartCourses={setCartCourses} setFinalAmounts={setFinalAmounts}
                />
            </Dialog>
        </div>
    )
}

export default TestSeriesSection