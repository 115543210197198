import React, { useEffect, useRef, useState } from "react";
import { Navigation } from "./navigation";
import { SecondSection } from "./secondSection";
import { Footer } from "./Footer";
import TestSeriesSection, { SectionTestSeries } from "./SectionTestSeries";
import HomeSection2 from "./homepage/RatingSection";
import FacultySection from "./homepage/FacultyList";
import instId from "./network/instituteId";
import Endpoints, { BASE_URL } from "./network/endpoints";
import axios from "axios";
import { ReelsSection } from "./ReelsSection";
import IframFormSection from "./homepage/IframeSection";
import FaQqSection from "./homepage/FaqSection";
import { useLocation } from "react-router-dom";
import { TestSeriesCard } from "./homepage/TestSeriesCard";
import ExamPrepSection from "./homepage/ExamPrep";
import TopSection from "./homepage/TopSection";
import DownloadAppSection from "./homepage/DownloadApp";
import SectionGellary from "./Gallery";


const Home = ({ downloadAppRef, aboutUsRef }) => {

  const iframeFormRef = useRef(null);
  const location = useLocation();
  const [endpointsUrl, setEndpointsUrl] = useState('')
  const [instituteRes, setInstituteRes] = useState('')

  useEffect(() => {
    getInstituteDetail();
  }, [])

  useEffect(() => {
    if (location.state?.scrollTo === "contact") {
      setTimeout(() => {
        iframeFormRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  }, [location]);

  const getInstituteDetail = async () => {
    try {
      let requestOptions = {
        // headers: { "X-Auth": token },
        withCredentials: false,
      };
      const response = await axios.get(
        BASE_URL + "/getMetaData/fetch-institute/" + instId,
        requestOptions
      );
      if (response?.data?.errorCode === 0) {
        setInstituteRes(response?.data?.institute)
        setEndpointsUrl(response?.data?.instituteTechSetting?.mediaUrl)
        Endpoints.mediaBaseUrl = response?.data?.instituteTechSetting?.mediaUrl
      };
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <TopSection endpointsUrl={endpointsUrl} />
      <SecondSection endpointsUrl={endpointsUrl} />
      <ReelsSection endpointsUrl={endpointsUrl} instituteRes={instituteRes} />
      {/* <SectionGellary endpointsUrl={endpointsUrl} /> */}
      {/* <div ref={iframeFormRef}>
      <IframFormSection endpointsUrl={endpointsUrl} />
      </div> */}
      <TestSeriesSection endpointsUrl={endpointsUrl} />
      <div ref={aboutUsRef}>
        <FacultySection endpointsUrl={endpointsUrl} />
      </div>

      <div ref={downloadAppRef}>
        <DownloadAppSection />
      </div>

      {/* <FaQqSection endpointsUrl={endpointsUrl} /> */}
    </div>
  )
};

export default Home;