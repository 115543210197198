import React, { useEffect, useRef } from "react";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Home from "./components/Home";
import PlanLists from "./components/PlanList";
import ThankyouPage from "./components/ThankyouPage";
import PrivacyAndPolicy from "./components/PrivacyPolicy";
import { Navigation } from "./components/navigation";
import { Footer } from "./components/Footer";
import { CourseDrips } from "./components/courseDrips/CourseDrips";
import AboutUs from "./components/AboutUs";
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import FreeResources from "./components/freeResources/FreeResources";
import MultipleCourseCart from "./components/cartCourses/AddedCourseeCart";
import CoursesDetail from "./components/cartCourses/ViewMoreCourse";
import ExploreAllCoursesSection from "./components/cartCourses/ExploreAllCourses";
import whatsApplog from "./djclogo.png";
import TermsAndConditions from "./components/footerPages/TermsAndConditions";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {

  const downloadAppRef = useRef(null);
  const aboutUsRef = useRef(null);

  useEffect(() => {
    if (window.location.hash === "#downloadOurApp") {
      downloadAppRef.current?.scrollIntoView({ behavior: "smooth" });
    }
    if (window.location.hash === "#downloadOurApp") {
      aboutUsRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <React.Fragment>
      <Router>
        <Navigation downloadAppRef={downloadAppRef} />
        <Routes>
          <Route path="/" element={<Home downloadAppRef={downloadAppRef} aboutUsRef={aboutUsRef} />} />
          <Route path="/course-content" element={<CourseDrips />} />
          <Route path="/test-series" element={<PlanLists />} />
          <Route path="/thankyou-page" element={<ThankyouPage />} />
          <Route path="/privacypolicy" element={<PrivacyAndPolicy />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/free-resouces" element={<FreeResources />} />
          <Route path='/cart-courses' element={<MultipleCourseCart />} />
          <Route path='/course' element={<CoursesDetail />} />
          <Route path='/Explore-all-course' element={<ExploreAllCoursesSection />} />
          <Route path='/terms-conditions' element={<TermsAndConditions />} />
        </Routes>
        <Footer aboutUsRef={aboutUsRef} />
        <FloatingWhatsApp avatar={whatsApplog} phoneNumber={'+91-8827692257'} accountName="DUSHYANT JAIN CLASSES"
          chatMessage="Hello! How can I help you?" className="classcart-whatsapp-avatar" />
      </Router>

    </React.Fragment>
  );
};

export default App;
