import { Box, Button, Card, Divider, Grid, Paper, Stack, Typography, useMediaQuery } from '@mui/material';
import React from 'react'
import StarIcon from '@mui/icons-material/Star';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

const DownloadAppSection = ({ endpointsUrl }) => {

    const isMobile = useMediaQuery("(min-width:600px)");

    const handleWindowStore = () =>{
        const url = 'https://play.google.com/store/apps/details?id=com.classiolabs.dushyantjain'
        window.open(url, '_blank', 'noreferrer');
    }

    const handlePlayStore = () =>{
        const url = 'https://apps.microsoft.com/store/detail/9MXV58V7Z2D7'
        window.open(url, '_blank', 'noreferrer');
    }

    return (
        <div style={{ paddingLeft: isMobile ? '8rem' : '1rem', paddingRight: isMobile ? '8rem' : '1rem', paddingTop: isMobile ? '8rem' : '1rem', paddingBottom: isMobile ? '8rem' : '1rem' }}>
            <Grid container sx={{ padding: '9rem 0 0 0' }}>
                <Grid item xs={12} sm={7} md={7} lg={7} sx={{ p: 1 }}>
                    <Typography variant='h3' sx={{ fontWeight: "bold", mt: isMobile ? "" : 4 }}>
                        Download the <span style={{ color: "#1250B9" }}>DUSHYANT JAIN CLASSES</span> app now
                    </Typography>
                    <Typography variant='h3' sx={{ fontWeight: "bold" }}>
                        and experience a <span style={{ color: "#1250B9" }}>new way of learning</span>
                    </Typography>
                    <Box sx={{ mt: 5 }}>
                        <img src={'img/apps.png'} style={{ width: !isMobile ? "100%" : "50%" }} />
                    </Box>
                    <Stack container sx={{ mt: 5, display: isMobile ? "block" : "flex", justifyContent: 'center' }} direction={'row'} gap={1} >

                        <img src={'img/app.png'} style={{ width: !isMobile ? "33%" : "", marginBottom: "10px", paddingLeft: !isMobile ? "5px" : "", paddingRight: !isMobile ? "5px" : "10px" }} />

                        <img src={'img/google.png'} style={{ width: !isMobile ? "33%" : "", marginBottom: "10px", paddingLeft: !isMobile ? "5px" : "", paddingRight: !isMobile ? "5px" : "10px", cursor: "pointer" }} onClick={handlePlayStore} />

                        <img src={'img/window.png'} style={{ width: !isMobile ? "33%" : "", marginBottom: "10px", paddingLeft: !isMobile ? "5px" : "", paddingRight: !isMobile ? "5px" : "10px" , cursor: "pointer"}} onClick={handleWindowStore} />

                    </Stack>
                </Grid>
                <Grid item xs={12} sm={5} md={5} lg={5}>
                    <img src={'img/download.png'} style={{ width: !isMobile ? "100%" : "100%", padding: !isMobile ? "20px" : '', borderRadius: "8px", boxShadow: "0 0 10px rgba(0, 0, 0, 0.15)" }} />
                </Grid>
            </Grid>
        </div >
    )
}

export default DownloadAppSection