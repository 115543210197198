import { Box, Card, Divider, Grid, Paper, Stack, Typography, useMediaQuery } from '@mui/material';
import React from 'react'
import StarRateIcon from '@mui/icons-material/StarRate';

const HomeSection2 = () => {

    const isMobile = useMediaQuery("(min-width:600px)");

    return (
        <div style={{ paddingLeft: isMobile ? '6rem' : '0rem', paddingRight: isMobile ? '6rem' : '0', paddingTop: isMobile ? '2rem' : '0', paddingBottom: isMobile ? '2rem' : '0' }}>
            <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box
                        className='ratting-box'
                        sx={{
                            background: "#1356C5",
                            color: '#fff',
                            // width: '100%',
                            // p: isMobile ? 4 : 1,
                            boxShadow: '#3e7add82 2px 6px 18px 0px',
                            borderRadius: isMobile ? '15px' : "",
                            margin: isMobile ? "0px 80px" : ""
                        }}
                    >

                        <Grid container sx={{ textAlign: "center", padding: isMobile ? "50px 26px" : "20px" }}>
                            <Grid item xs={6} sm={6} md={3} lg={3} sx={{ borderRight: isMobile ? "2px solid #ffffffab" : "2px solid #ffffffab", mb: isMobile ? "" : 3 }}>
                                <Typography><StarRateIcon sx={{ color: "#fff", mb: 1, fontSize: "35px" }} /></Typography>
                                <Typography
                                    sx={{ mb: 1, lineHeight: "42px" }}
                                    fontSize={isMobile ? '30px' : "18px"}
                                    fontWeight={'bold'}
                                >
                                    4.6 Rating
                                </Typography>
                                <Typography sx={{ mb: 2, fontSize: isMobile ? "20px" : "14px" }}>
                                    11467+ reviews
                                </Typography>

                            </Grid>

                            <Grid item xs={6} sm={6} md={3} lg={3} sx={{ borderRight: isMobile ? "2px solid #ffffffab" : "", mb: isMobile ? "" : 3 }}>
                                <Typography> <img src="img/students.png" style={{ height: "35px", marginBottom: "8px" }} /></Typography>
                                <Typography
                                    sx={{ mb: 1, lineHeight: "42px" }}
                                    fontSize={isMobile ? '30px' : "18px"}
                                    fontWeight={'bold'}
                                >
                                    10 Thousand+
                                </Typography>
                                <Typography sx={{ mb: 2, fontSize: isMobile ? "20px" : "14px" }}>
                                    Happy Students
                                </Typography>
                            </Grid>

                            <Grid item xs={6} sm={6} md={3} lg={3} sx={{ borderRight: isMobile ? "2px solid #ffffffab" : "2px solid #ffffffab" }}>
                                <Typography><img src="img/tutor.png" style={{ height: "35px", marginBottom: "8px" }} /></Typography>
                                <Typography
                                    sx={{ mb: 1, lineHeight: "42px" }}
                                    fontSize={isMobile ? '30px' : "18px"}
                                    fontWeight={'bold'}
                                >
                                    200+
                                </Typography>
                                <Typography sx={{ mb: 2, fontSize: "16px" }}>
                                    Expert Tutors
                                </Typography>
                            </Grid>

                            <Grid item xs={6} sm={6} md={3} lg={3}>
                                <Typography><img src="img/test.png" style={{ height: "35px", marginBottom: "8px" }} /></Typography>
                                <Typography
                                    sx={{ mb: 1, lineHeight: "42px" }}
                                    fontSize={isMobile ? '30px' : "18px"}
                                    fontWeight={'bold'}
                                >
                                    10,000+
                                </Typography>
                                <Typography sx={{ mb: 2, fontSize: isMobile ? "20px" : "14px" }}>
                                    Test Conducted
                                </Typography>
                            </Grid>
                        </Grid>

                    </Box>
                </Grid>
            </Grid>
        </div >
    )
}

export default HomeSection2