import { Box, Button, Card, Divider, Grid, Paper, Stack, Typography, useMediaQuery } from '@mui/material';
import React from 'react'
import StarIcon from '@mui/icons-material/Star';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { useNavigate } from 'react-router-dom';

const TopSection = ({ endpointsUrl }) => {

    const navigate = useNavigate();
    const isMobile = useMediaQuery("(min-width:600px)");

    const handleCheckoutCourse = () => {
        navigate('/Explore-all-course')
    }

    return (
        <div style={{ paddingLeft: isMobile ? '6rem' : '0rem', paddingRight: isMobile ? '6rem' : '0', paddingTop: isMobile ? '2rem' : '0', paddingBottom: isMobile ? '2rem' : '0' }}>
            <Grid container sx={{ padding: isMobile ? '9rem 0 0 0' : "11rem 5px 5px 5px" }}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box sx={{ textAlign: "center" }}>
                        <p style={{ fontSize: "12px", color: '#000' }}>Welcome FUTURE CS at DUSHYANT JAIN CLASSES</p>
                        <Typography variant='h3' sx={{ mb: 3, mt: 3, color: "black" }} fontWeight={'bold'}>Let's Prepare the Right way!!</Typography>
                        <p style={{ fontSize: "12px", color: '#000' }}>One Stop Destination For Company Secretary Preparation</p>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                            <p style={{ fontSize: "12px", color: '#000', background: 'rgb(188 188 188 / 26%)', width: "fit-content", padding: isMobile ? "10px 20px" : "10px", borderRadius: '8px', display: "flex" }}><StarIcon color='warning' />&nbsp;  Rated 4.8 By Enrolled Students On Google, Justdial, SLDIN</p>
                        </Box>
                        <Stack direction={'row'} gap={1} sx={{ justifyContent: "center", mt: 3 }}>
                            <Button onClick={handleCheckoutCourse} sx={{ background: "#FEDD3D", fontWeight: "bold", color: "#000", px: isMobile ? 5 : 1, py: 2, fontSize: isMobile ? "12px " : "12px" }} className='button-hover'>Checkout our Courses</Button>
                            <Button onClick={() => window.location.href = "tel:+918827692257"} sx={{
                                background: "#113CAF", fontWeight: "bold", color: '#fff', px: isMobile ? 5 : 1, py: 2, fontSize: isMobile ? "12px " : "12px", "&:hover": {
                                    background: "#0D2E82",
                                }
                            }} className='button-hover'> <LocalPhoneIcon className="trin-trin" sx={{ color: "#fff" }} />&nbsp; Request a Callback</Button>
                        </Stack>
                    </Box>
                </Grid>
            </Grid>
        </div >
    )
}

export default TopSection