import { Box, Button, Card, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const ThankyouPage = () => {


    const navigate = useNavigate();

    const handleBacktoHome = () =>{

        navigate(-1);
    }

    return (
        <React.Fragment>
            <div id="testseries" className="" style={{ margin: "40px", textAlign: 'left', padding: '8rem 0 0 0', overflow: "hidden" }}>
                <div className="row" style={{ margin: 0 }}>
                    <div className="col-xs-12 col-md-12 col-lg-12">
                    <Typography sx={{ mt: 3, mb: 1, py: 1 }}>
                                                <Card sx={{ width: "100%", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", textAlign: "center" }}>
                                                    <Grid container sx={{ margin: "20px 0", justifyContent: "center" }}>
                                                        <Grid item xs={12} sm={4} md={4} lg={4}>
                                                            <Typography variant='h3'>
                                                                <img src="img/Thank you image size 4ratio3.jpg" alt="" style={{ margin: "5px", height: "250px" }} />

                                                            </Typography>
                                                            <Typography sx={{ fontWeight: "bold" }}>
                                                            For access to Test papers and to upload Answer Sheet, you need to download our Application. Currently the student dashboard is not available on the web.
                                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                    <img src="img/android.png" alt="" style={{ margin: "5px", width: "20px", height: "20px" }} />
                                                                    <img src="img/apple.png" alt="" style={{ margin: "5px", width: "20px", height: "20px" }} />
                                                                </Box>

                                                            </Typography>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                sx={{ width: "200px", padding: "10px", margin: "15px" }}
                                                                onClick={handleBacktoHome}
                                                            >
                                                               ENROL FOR ANOTHER TEST SERIES
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Card>
                                            </Typography>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};

export default ThankyouPage;