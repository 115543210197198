// export default class Endpoints {
// 	// static baseUrl="http://192.168.1.125:7070";

import { useEffect } from "react";
import instId from "./instituteId";
import axios from "axios";

// 	static baseURL = "http://192.168.0.242:6060";
// 	static mediaBaseUrl = "https://media.theoogway.com/";
// }

export const BASE_URL = "https://prodapi.classiolabs.com/";

const Endpoints = {
  // baseURL: "https://api.softkitesinfo.com/",
  baseURL: "https://prodapi.classiolabs.com/",
  // mediaBaseUrl: "https://media.theoogway.com/",
  // mediaBaseUrl: "https://classiopace.in-maa-1.linodeobjects.com/",
  // mediaBaseUrl: "https://classiosafaltaias.in-maa-1.linodeobjects.com/",
  mediaBaseUrl: "",
};
export default Endpoints;
